import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: ${theme.colors.white};

  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(16)};
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
  border: 1px solid rgb(204, 204, 204);
`;

interface TitleProps {
  color: string;
}

export const Title = styled.h3<TitleProps>`
  font-size: ${fluidSize(12, 14)};
  color: ${props => props.color};
  margin: 0;
`;

export const Text = styled.span`
  font-size: ${fluidSize(10, 12)};
`;
