import React from "react";
import Container from "../ui/container";
import Menu from "../menu/menu";
import {Wrapper} from "./layout.style";

interface Props {
  home?: boolean;
  children: JSX.Element;
  active?: "dashboard" | "data" | "settings";
}

const Layout = ({home = false, children, active}: Props): JSX.Element => {
  return (
    <Wrapper withImage={home}>
      <Container>
        {children}
        <Menu
          active={active}
          variant={home ? "glass" : "grey"}
          goBack={!home}
        />
      </Container>
    </Wrapper>
  );
};

export default Layout;
