import Layout from "@/components/layout/layout";
import React, {useContext, useEffect, useState} from "react";
import {
  Wrapper,
  Title,
  Box,
  BoxHeader,
  BoxContent,
  Column,
  ChartWrapper,
  BoxFooter,
  Row,
  ButtonsWrapper,
  DefaultButton,
  SaveButton,
  GuideText,
} from "./settings.style";
import NumberInput from "@/components/ui/numberInput/numberInput";
import theme from "@/styles/theme";
import CheckboxInput from "@/components/ui/checkboxInput/checkboxInput";
import {ProjectContext} from "@/contexts/projectContext";
import SettingsChart from "@/components/charts/settingsChart/settingsChart";
import type {SettingsChartData} from "@/components/charts/settingsChart/settingsChart";

const Settings = (): JSX.Element => {
  const {project} = useContext(ProjectContext);
  const {settings} = project;
  const [dischargePoint, setDischargePoint] = useState(settings.dischargePoint);
  const [chargePoint, setChargePoint] = useState(settings.chargePoint);
  const [minPower, setMinPower] = useState(settings.minPower);
  const [maxPower, setMaxPower] = useState(settings.maxPower);
  const [chartData, setChartData] = useState<SettingsChartData[]>([]);

  const refreshChartData = (): void => {
    const newChartData: SettingsChartData[] = settings.chart.map(value => {
      if (value > dischargePoint) {
        const belowChargePoint = 0;
        const newValue = dischargePoint;
        const aboveDischargePoint = value - dischargePoint;
        return {
          value: newValue,
          aboveDischargePoint,
          belowChargePoint,
        };
      }
      if (value < -chargePoint) {
        const aboveDischargePoint = 0;
        const newValue = -chargePoint;
        const belowChargePoint = -(-value - chargePoint);
        return {
          value: newValue,
          aboveDischargePoint,
          belowChargePoint,
        };
      }
      return {
        value,
        aboveDischargePoint: 0,
        belowChargePoint: 0,
      };
    });

    setChartData(newChartData);
  };

  useEffect(() => {
    refreshChartData();
  }, [dischargePoint, chargePoint, project]);

  useEffect(() => {
    setDefaultValue();
  }, [project]);

  const setDefaultValue = (): void => {
    const {settings} = project;
    setChargePoint(settings.chargePoint);
    setDischargePoint(settings.dischargePoint);
    setMinPower(settings.minPower);
    setMaxPower(settings.maxPower);
  };

  return (
    <Layout home={false} active="settings">
      <Wrapper>
        <Box>
          <BoxHeader>
            <Title>Peak shaving settings</Title>
            <GuideText>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none">
                <path
                  d="M7.3335 5.16659H8.66683V6.49992H7.3335V5.16659ZM7.3335 7.83325H8.66683V11.8333H7.3335V7.83325ZM8.00016 1.83325C4.32016 1.83325 1.3335 4.81992 1.3335 8.49992C1.3335 12.1799 4.32016 15.1666 8.00016 15.1666C11.6802 15.1666 14.6668 12.1799 14.6668 8.49992C14.6668 4.81992 11.6802 1.83325 8.00016 1.83325ZM8.00016 13.8333C5.06016 13.8333 2.66683 11.4399 2.66683 8.49992C2.66683 5.55992 5.06016 3.16659 8.00016 3.16659C10.9402 3.16659 13.3335 5.55992 13.3335 8.49992C13.3335 11.4399 10.9402 13.8333 8.00016 13.8333Z"
                  fill="#000099"
                />
              </svg>
              Guide
            </GuideText>
          </BoxHeader>
          <BoxContent>
            <Column>
              <NumberInput
                value={dischargePoint}
                onChange={value => {
                  setDischargePoint(value);
                }}
                min={0}
                color={theme.colors.blue[400]}
                step={100}
                label="Discharge Point (W)"
              />
              <NumberInput
                value={chargePoint}
                onChange={value => {
                  setChargePoint(value);
                }}
                min={0}
                color={theme.colors.lightGreen[400]}
                step={100}
                label="Charge Point (W)"
              />
              <NumberInput
                value={minPower}
                onChange={value => {
                  setMinPower(value);
                }}
                min={0}
                color="#009"
                step={100}
                label="Max Charge Power (W)"
              />
              <NumberInput
                onChange={value => {
                  setMaxPower(value);
                }}
                value={maxPower}
                min={0}
                color="#009"
                step={100}
                label="Max Discharge Power (W)"
              />
            </Column>
            <ChartWrapper>
              <SettingsChart
                data={chartData}
                chargePoint={chargePoint}
                dischargePoint={dischargePoint}
              />
            </ChartWrapper>
          </BoxContent>
          <BoxFooter>
            <Row>
              <CheckboxInput
                color={theme.colors.lightGreen[400]}
                defaultChecked={false}
                label="Keep service active when idle"
              />
              <ButtonsWrapper>
                <DefaultButton onClick={setDefaultValue} color="#009">
                  Set to default
                </DefaultButton>
                <SaveButton color="#009">Save</SaveButton>
              </ButtonsWrapper>
            </Row>
          </BoxFooter>
        </Box>
      </Wrapper>
    </Layout>
  );
};

export default Settings;
