import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";

interface VariantProps {
  variant: "grey" | "glass";
}

export const Wrapper = styled.nav<VariantProps>`
  z-index: 100;
  height: ${pxToRem(96)};
  width: inherit;
  position: sticky;
  bottom: 0;
  color: ${props =>
    props.variant === "glass" ? theme.colors.white : theme.colors.grey[300]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${pxToRem(32)};
  padding-right: ${pxToRem(32)};
  flex-wrap: wrap;
  border-radius: ${pxToRem(16)};
  ${({variant}) =>
    variant === "glass" &&
    css`
      border-radius: 16px 16px 0px 0px;
      border-top: 1px solid rgba(201, 202, 202, 0.32);
      border-right: 1px solid rgba(201, 202, 202, 0.32);
      border-left: 1px solid rgba(201, 202, 202, 0.32);
      background: linear-gradient(
        180deg,
        rgba(159, 160, 160, 0.08) 4.17%,
        rgba(255, 255, 255, 0.08) 100%,
        rgba(239, 239, 239, 0.08) 100%
      );
      backdrop-filter: blur(12px);
    `}
  ${({variant}) =>
    variant === "grey" &&
    css`
      background-color: ${theme.colors.grey[100]};
    `}
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  position: relative;
  height: 100%;
  flex: 1;
  max-width: 380px;
  flex-grow: 3;
  flex-basis: 0;
  min-width: 0;
`;

export const MenuItemsWrapperBorder = styled.div<VariantProps>`
  position: absolute;
  left: ${pxToRem(21)};
  right: ${pxToRem(21)};
  bottom: 2px;
  bottom: 0;
  background-color: ${props =>
    props.variant === "glass" ? theme.colors.white : theme.colors.black};
  height: 5px;
  border-radius: 100px;
`;

export const MenuText = styled.span`
  ${({theme}) => theme.breakpoints.down("lg")} {
    display: none;
  }
`;

export const SystemCheckedText = styled.span`
  ${({theme}) => theme.breakpoints.down("lg")} {
    display: none;
  }
`;

interface MenuItemProps {
  active: boolean;
}

export const MenuItem = styled(Link)<MenuItemProps>`
  color: inherit;
  font-weight: 500;
  font-size: ${fluidSize(12, 16)};
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.active ? theme.colors.white : "transparent"};
  height: ${pxToRem(24)};
  border-radius: ${pxToRem(24)};
  padding: 8px 16px 8px 16px;
  align-self: center;
  color: ${props => (props.active ? "#009" : "inherit")};
`;

export const MenuIcon = styled.svg`
  margin-right: ${pxToRem(8)};
`;

export const SystemStatusDescription = styled.span`
  color: ${theme.colors.lightGreen[400]};
  font-weight: 700;
  font-size: ${fluidSize(12, 16)};
  height: 32px;
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
`;

export const SystemStatusIconWrapper = styled.span`
  display: inline-flex;
  background-color: ${theme.colors.lightGreen[400]};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: ${pxToRem(4)};
  margin-right: ${pxToRem(16)};
`;

export const LocalizationDropdownWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: end;
`;

export const LocalizationDropdownContent = styled.div`
  width: ${pxToRem(250)};
`;

export const BackToHomeLink = styled(Link)`
  color: ${theme.colors.grey[300]};
  font-size: ${fluidSize(14, 16)};
  font-weight: 500;
  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
  flex-basis: 0;
`;
