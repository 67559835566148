import styled, {css} from "styled-components";
import {CardContent, CardGlass} from "../ui/card";
import theme from "@/styles/theme";
import {fluidSize, pxToRem} from "@/styles/helpers";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(16)};
`;

export const Content = styled(CardContent)`
  display: flex;
  flex-direction: column;
`;

export const InfoCard = styled(CardGlass)`
  display: flex;
  flex-direction: column;
`;

export const InfoDescription = styled.span`
  color: ${theme.colors.grey[500]};
  font-size: ${fluidSize(10, 12)};
  margin-bottom: ${pxToRem(8)};
`;

interface ValueProps {
  positive: boolean;
}

export const InfoValue = styled.span<ValueProps>`
  font-weight: 700;
  font-size: ${fluidSize(10, 12)};
  color: ${props =>
    props.positive ? theme.colors.lightGreen[400] : theme.colors.red[400]};
  display: inline-flex;
`;

export const InfoValueIcon = styled.span<ValueProps>`
  padding: ${pxToRem(3)};
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  border-radius: 50%;
  background-color: ${props =>
    props.positive ? theme.colors.lightGreen[400] : theme.colors.red[400]};
  margin-right: ${pxToRem(8)};
  display: flex;
  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    ${({positive}) =>
      positive &&
      css`
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M3.66462 6.61451L1.92712 4.87701L1.33545 5.46451L3.66462 7.79368L8.66462 2.79368L8.07712 2.20618L3.66462 6.61451Z" fill="%23D2E6AE" /></svg>');
      `}
    ${({positive}) =>
      !positive &&
      css`
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M7.91659 2.67087L7.32909 2.08337L4.99992 4.41254L2.67075 2.08337L2.08325 2.67087L4.41242 5.00004L2.08325 7.32921L2.67075 7.91671L4.99992 5.58754L7.32909 7.91671L7.91659 7.32921L5.58742 5.00004L7.91659 2.67087Z" fill="%23F5C9BF" /></svg>');
      `}
  }
`;
