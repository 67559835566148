import React, {useEffect, useRef, useState} from "react";
import {
  ArrowIcon,
  OpenButton,
  Option,
  OptionIconWrapper,
  OptionsWrapper,
  Wrapper,
} from "./dropdown.style";

interface option<T> {
  value: T;
  text: string;
}

interface Props<T> {
  options: Array<option<T>>;
  defaultValue?: option<T>;
  onChange: (option: option<T>) => void;
}

const Dropdown = <T,>({
  options,
  defaultValue,
  onChange,
}: Props<T>): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<option<T>>(
    defaultValue ?? options[0],
  );

  const handleOpen = (): void => {
    setOpen(!open);
  };

  const handleSelect = (option: option<T>): void => {
    setSelected(option);
    onChange(option);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", closeOpenMenus);
    return () => {
      window.removeEventListener("click", closeOpenMenus);
    };
  }, []);

  const closeOpenMenus = (e: MouseEvent): void => {
    if (e.target !== null && dropdownRef.current !== null) {
      if (open && !dropdownRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    }
  };

  return (
    <Wrapper ref={dropdownRef}>
      <OpenButton onClick={handleOpen}>
        {selected.text}
        <ArrowIcon
          open={open}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M6 14.2951L7.41 15.7051L12 11.1251L16.59 15.7051L18 14.2951L12 8.29508L6 14.2951Z"
            fill="#525252"
          />
        </ArrowIcon>
      </OpenButton>
      <OptionsWrapper open={open}>
        {options.map((option, key) => {
          return (
            <Option
              key={key}
              onClick={() => {
                handleSelect(option);
              }}>
              {option.text}
              <OptionIconWrapper selected={selected.value === option.value}>
                {selected.value === option.value && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none">
                    <path
                      d="M3.66462 6.61439L1.92712 4.87689L1.33545 5.46439L3.66462 7.79356L8.66462 2.79355L8.07712 2.20605L3.66462 6.61439Z"
                      fill="#D2E6AE"
                    />
                  </svg>
                )}
              </OptionIconWrapper>
            </Option>
          );
        })}
      </OptionsWrapper>
    </Wrapper>
  );
};

export default Dropdown;
