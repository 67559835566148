import theme from "@/styles/theme";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {LegendText, LegendTextCapitalize} from "./emissionChart.style";
import CustomizedTick from "./customizedTick";

export interface ChartData {
  name: string;
  energy: number;
  tco2: number;
  consumption: number;
}

interface Props {
  legend: boolean;
  data: ChartData[];
}

const renderLegendText = (value: string, entry: any): JSX.Element => {
  if (entry.dataKey === "tco2") {
    return (
      <LegendText>
        tCO<sup>2</sup>
      </LegendText>
    );
  }
  return <LegendTextCapitalize>{value}</LegendTextCapitalize>;
};

const EmissionChart = ({legend, data}: Props): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{top: 5, left: 0, right: 0, bottom: 0}}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tick={<CustomizedTick />}
          axisLine={false}
          tickLine={false}
          xAxisId={0}
        />
        <XAxis xAxisId={1} hide />
        <XAxis xAxisId={2} hide />
        <YAxis
          tick={<CustomizedTick />}
          axisLine={false}
          tickLine={false}
          ticks={[0, 5000, 10000, 15000, 20000, 25000, 30000]}
          interval={0}
        />
        {legend && (
          <Legend
            align="right"
            iconType="circle"
            formatter={renderLegendText}
          />
        )}
        <Bar
          dataKey="energy"
          xAxisId={2}
          fill={theme.colors.lightGreen[300]}
          radius={[16, 16, 16, 16]}
          barSize={12}
        />
        <Bar
          dataKey="tco2"
          xAxisId={1}
          fill={theme.colors.green[300]}
          radius={[16, 16, 16, 16]}
          barSize={12}
        />
        <Bar
          dataKey="consumption"
          xAxisId={0}
          fill={theme.colors.lightBlue[300]}
          radius={[16, 16, 16, 16]}
          barSize={12}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EmissionChart;
