import {ContainedButton, OutlinedButton} from "@/components/ui/button";
import Card, {CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Box = styled(Card)`
  min-height: calc(100vh - 96px - 64px);
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding-top: ${pxToRem(32)};
  padding-bottom: ${pxToRem(32)};
`;

export const BoxHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  padding: ${pxToRem(40)};
`;

export const Title = styled(CardTitle)`
  color: ${theme.colors.black};
  font-size: ${fluidSize(22, 24)};
  font-weight: 700;
`;

export const BoxContent = styled(CardContent)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-left: ${pxToRem(40)};
  padding-right: ${pxToRem(40)};
  padding-bottom: ${pxToRem(40)};
  flex: 1;
`;

export const BoxFooter = styled(CardContent)`
  display: flex;
  align-items: end;
  padding-left: ${pxToRem(40)};
  padding-right: ${pxToRem(40)};
  padding-bottom: ${pxToRem(40)};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export const ChartWrapper = styled.div`
  max-height: 500px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${pxToRem(16)};
`;

export const DefaultButton = styled(OutlinedButton)`
  font-size: ${pxToRem(16)};
  font-weight: 700;
  padding: ${pxToRem(16)};
`;

export const SaveButton = styled(ContainedButton)`
  font-size: ${pxToRem(16)};
  font-weight: 700;
  padding-top: ${pxToRem(16)};
  padding-bottom: ${pxToRem(16)};
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
`;

export const GuideText = styled.span`
  font-weight: 700;
  color: #009;
  font-size: ${fluidSize(12, 14)};
  display: flex;
  align-items: center;
  gap: ${pxToRem(8)};
`;
