import React, {useEffect, useRef, useState} from "react";
import PowerChart, {type PowerChartData} from "./powerChart";
import {
  NumberText,
  ProgressSvg,
  TextWrapper,
  TextWrapperContent,
  UnitText,
  Wrapper,
} from "./powerChartWithLabel.style";
import theme from "@/styles/theme";

interface Props {
  data: PowerChartData;
  index: string;
}

const PowerChartWithLabel = ({index, data}: Props): JSX.Element => {
  const svgPathRef = useRef<SVGPathElement>(null);
  const [motionPath, setMotionPath] = useState("");
  const svgMotionPathRef = useRef<SVGPathElement>(null);
  const moreThanHalf = data.value * 2 > data.max;
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [data]);

  // Draw motion line for arrow icon to move
  useEffect(() => {
    if (svgPathRef.current !== null && svgMotionPathRef.current !== null) {
      const percent = data.value / data.max;
      const pathLength = svgPathRef.current.getTotalLength();
      const endPoint = pathLength * percent;
      const {x, y} = svgPathRef.current.getPointAtLength(0);
      let d = `M${x} ${y}`;
      for (let i = 0; i <= endPoint; i = i + 5) {
        const {x, y} = svgPathRef.current.getPointAtLength(i);
        d += `L${x} ${y}`;
      }
      setMotionPath(d);
      svgMotionPathRef.current.setAttribute("d", d);
    }
  }, [data]);

  return (
    <Wrapper key={key}>
      <PowerChart data={data} index={index} />
      <TextWrapper>
        <TextWrapperContent>
          <ProgressSvg width="147" height="96" fill="none" viewBox="0 0 147 42">
            <path
              ref={svgPathRef}
              d="M 1.312 70.7359 C 1.312 52.26 8.9174 34.5407 22.4551 21.4763 C 35.9929 8.4118 54.3539 1.0723 73.4991 1.0723 C 92.6444 1.0723 111.005 8.4118 124.543 21.4763 C 138.081 34.5407 145.686 52.2599 145.686 70.7359"
              stroke={
                moreThanHalf
                  ? theme.colors.lightGreen[400]
                  : theme.colors.lightBlue[400]
              }
              strokeWidth="1.18405"
              strokeLinecap="round"
              strokeDasharray="0 0.61 6.09 0 0.61 6.09"
            />

            <path
              d={motionPath}
              ref={svgMotionPathRef}
              id={`motionPath-${index}`}
              fill="transparent"
            />
            <circle
              r="5"
              fill={
                moreThanHalf
                  ? theme.colors.lightGreen[400]
                  : theme.colors.lightBlue[400]
              }>
              <animateMotion fill="freeze" dur="1.5s" repeatCount="1">
                <mpath href={`#motionPath-${index}`} />
              </animateMotion>
            </circle>
          </ProgressSvg>
          <NumberText
            color={
              moreThanHalf
                ? theme.colors.lightGreen[400]
                : theme.colors.lightBlue[400]
            }>
            {data.value}
          </NumberText>
          <UnitText>kW</UnitText>
        </TextWrapperContent>
      </TextWrapper>
    </Wrapper>
  );
};

export default PowerChartWithLabel;
