import React from "react";
import {RadialBar, RadialBarChart, ResponsiveContainer} from "recharts";
import theme from "@/styles/theme";

export interface PowerChartData {
  value: number;
  max: number;
}

interface Props {
  data: PowerChartData;
  index: string;
}

const PowerChart = ({data, index}: Props): JSX.Element => {
  const chartData = [data];
  const moreThanHalf = data.value * 2 > data.max;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
        cy="85%"
        innerRadius={100}
        outerRadius={170}
        startAngle={180}
        endAngle={0}
        barSize={35}
        data={chartData}>
        <defs>
          <linearGradient
            id="powerChartBackground"
            x1="59.4361"
            y1="46.8799"
            x2="215.693"
            y2="46.8799"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#008CAA" />
            <stop offset="1" stopColor="#7CBD27" />
          </linearGradient>
        </defs>

        <RadialBar opacity={0} dataKey="max" />
        <RadialBar
          background={{
            fill: moreThanHalf
              ? theme.colors.green[100]
              : theme.colors.lightBlue[100],
          }}
          cornerRadius={80}
          dataKey="value"
          fill="url(#powerChartBackground)"
        />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default PowerChart;
