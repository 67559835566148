import {fluidSize} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 300px;
  height: 160px;
  position: relative;
`;

export const TextWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  translate: -50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextWrapperContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProgressSvg = styled.svg`
  position: absolute;
  bottom: 20px;
`;

interface NumberTextProps {
  color: string;
}

export const NumberText = styled.span<NumberTextProps>`
  display: block;
  font-size: ${fluidSize(28, 32)};
  font-weight: 700;
  text-align: center;
  color: ${props => props.color};
`;

export const UnitText = styled.span`
  font-size: ${fluidSize(14, 16)};
  text-align: center;
  color: ${theme.colors.grey[500]};
`;
