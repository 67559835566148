import styled from "styled-components";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";

const Card = styled.div`
  background: ${theme.colors.white};

  border-radius: ${pxToRem(16)};
`;

export const CardGlass = styled.div`
  border: 1px solid rgba(201, 202, 202, 0.32);
  background: linear-gradient(
    180deg,
    rgba(159, 160, 160, 0.08) 4.17%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(239, 239, 239, 0.08) 100%
  );
`;

export const CardContent = styled.div`
  padding: ${pxToRem(16)};
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: baseline;
  padding: ${pxToRem(16)};
`;

export const CardHeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CardTitle = styled.h2`
  font-size: ${fluidSize(14, 16)};
  font-weight: 400;
  color: ${theme.colors.grey[500]};
  margin: 0;
`;

export const CardSubTitle = styled.span`
  font-size: ${fluidSize(10, 12)};
  color: ${theme.colors.grey[400]};
  margin-left: ${pxToRem(16)};
`;

export default Card;
