import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Instrument Sans";
    font-weight: 400;
  }
`;

export default GlobalStyle;
