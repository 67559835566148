import {pxToRem} from "@/styles/helpers";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  padding-top: ${pxToRem(32)};
  padding-bottom: ${pxToRem(32)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 0.6fr 1fr;
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr; */
  min-height: calc(100vh - 96px - 64px);
  column-gap: ${pxToRem(16)};
  row-gap: ${pxToRem(16)};
  ${({theme}) => theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${({theme}) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;
