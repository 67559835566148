import theme from "@/styles/theme";

const EternalSkyTower = {
  name: "Eternal Sky Tower",
  weather: {
    description: "Sunny",
    temperature: "23°C",
    icon: '<path d="M7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12Z" fill="currentColor"/><path d="M11 2H13V5H11V2ZM16.242 6.344L18.364 4.222L19.778 5.636L17.656 7.758L16.242 6.344ZM19 11H22V13H19V11ZM16.242 17.656L17.656 16.242L19.778 18.364L18.364 19.778L16.242 17.656ZM11 19H13V22H11V19ZM4.222 18.364L6.344 16.242L7.758 17.657L5.636 19.778L4.222 18.364ZM2 11H5V13H2V11ZM4.222 5.636L5.636 4.222L7.758 6.344L6.344 7.758L4.222 5.636Z" fill="currentColor"/>',
  },
  currentConsumption: 5.6,
  info: {
    door: true,
    fans: false,
    smoke: false,
    earth: true,
    grid: 18.5,
    pv: true,
  },
  battery: {
    battery: 54.7,
    amper: -0.7,
    volt: 33.0,
    temperature: 10.5,
    power: 5000,
    l1: 240,
    l2: 250,
    l3: 260,
  },
  energyConsumption: [
    {
      energy: 25500,
      consumption: 1900,
      tco2: 8500,
    },
    {
      energy: 13500,
      consumption: 1400,
      tco2: 5500,
    },
    {
      energy: 22500,
      consumption: 1750,
      tco2: 7800,
    },
    {
      energy: 14500,
      consumption: 1500,
      tco2: 6000,
    },
    {
      energy: 19500,
      consumption: 1650,
      tco2: 7300,
    },
    {
      energy: 27500,
      consumption: 1950,
      tco2: 9000,
    },
    {
      energy: 10500,
      consumption: 1150,
      tco2: 4200,
    },
    {
      energy: 28500,
      consumption: 2000,
      tco2: 9500,
    },
    {
      energy: 17500,
      consumption: 1600,
      tco2: 7200,
    },
    {
      energy: 12500,
      consumption: 1300,
      tco2: 4800,
    },
    {
      energy: 24500,
      consumption: 1850,
      tco2: 8200,
    },
    {
      energy: 16500,
      consumption: 1550,
      tco2: 6700,
    },
  ],
  energyConsumptionByYear: [
    [
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 25000,
        consumption: 1850,
        tco2: 9200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 8400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 10500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 6700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
    ],
    [
      {
        energy: 14500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6400,
      },
    ],
    [
      {
        energy: 21500,
        consumption: 1700,
        tco2: 9400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 16500,
        consumption: 1500,
        tco2: 9000,
      },
    ],
  ],
  emissionActivity: {
    consumption: 800,
    tco2: 350,
    energy: 300,
    scope1: 50,
    scope2: 100,
    scope3: 150,
  },
  scope1: {
    thisMonth: 77.74,
    lastMonth: 33.53,
    emissions: [
      {
        energy: 25500,
        consumption: 1100,
        tco2: 7200,
      },
      {
        energy: 25500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 13500,
        consumption: 1450,
        tco2: 4600,
      },
      {
        energy: 19500,
        consumption: 3050,
        tco2: 5800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 9500,
        consumption: 550,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumtpion", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  scope2: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumtpion", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  scope3: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumtpion", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  settings: {
    dischargePoint: 1200,
    chargePoint: 400,
    maxPower: 18000,
    minPower: 11000,
    chart: [800, 1000, 1400, 1500, 200, -400, -800, -900, -300, -200],
  },
  power: {
    grid: {
      value: 32,
      max: 100,
    },
    shaper: {
      value: 77,
      max: 100,
    },
    load: {
      value: 42,
      max: 100,
    },
  },
  history: [
    {
      batteryVoltage: 56,
      batteryCapacity: 100,
      meterPower: 0,
      storagePower: 96076,
    },
    {
      batteryVoltage: 35,
      batteryCapacity: 90,
      meterPower: 0,
      storagePower: 49331,
    },
    {
      batteryVoltage: 57,
      batteryCapacity: 70,
      meterPower: 2000,
      storagePower: 83433,
    },
    {
      batteryVoltage: 70,
      batteryCapacity: 50,
      meterPower: 2000,
      storagePower: 56370,
    },
    {
      batteryVoltage: 90,
      batteryCapacity: 30,
      meterPower: 4000,
      storagePower: 34332,
    },
    {
      batteryVoltage: 71,
      batteryCapacity: 40,
      meterPower: 4000,
      storagePower: 24274,
    },
    {
      batteryVoltage: 37,
      batteryCapacity: 60,
      meterPower: 4000,
      storagePower: 76142,
    },
    {
      batteryVoltage: 53,
      batteryCapacity: 80,
      meterPower: 4000,
      storagePower: 1778,
    },
    {
      batteryVoltage: 45,
      batteryCapacity: 50,
      meterPower: 3000,
      storagePower: 47675,
    },
    {
      batteryVoltage: 13,
      batteryCapacity: 45,
      meterPower: 0,
      storagePower: 5223,
    },
  ],
};

export default EternalSkyTower;
