export const pxToRem = (px: number, base = 16): string => {
  return (1 / base) * px + "rem";
};

export const clearPxToRem = (px: number, base = 16): number => {
  return (1 / base) * px;
};

const minBreakpoint = 320;
const maxBreakpoint = 1400;

export const fluidSize = (min: number, max: number): string => {
  min = clearPxToRem(min);
  max = clearPxToRem(max);
  const slope = (max - min) / maxBreakpoint - minBreakpoint;
  const slopeToUnit = slope;
  const intercept = min - slope * minBreakpoint;
  return `clamp(${min}rem, ${slopeToUnit}vw + ${intercept}rem, ${max}rem);`;
};
