import theme from "@/styles/theme";
import React from "react";

const CustomizedXTick = (props: any): JSX.Element => {
  const {x, y, payload} = props;

  return (
    <g transform={`translate(${x},${y + 20})`}>
      <text
        x={0}
        y={0}
        dy={2}
        dx={2}
        fontSize={10}
        textAnchor="end"
        fill={theme.colors.grey[300]}>
        {payload.value}
      </text>
    </g>
  );
};

export default CustomizedXTick;
