import styled from "styled-components";
import Card, {CardContent} from "../ui/card";
import {pxToRem} from "@/styles/helpers";

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: ${pxToRem(240)};
  height: 100%;
`;

export const EmissionChartWrapper = styled(CardContent)`
  padding-left: 0;
  padding-top: ${pxToRem(0)};
  flex: 5;
  max-height: 200px;
`;
