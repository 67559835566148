import styled, {css} from "styled-components";
import app_background from "@/assets/app-background.png";
import theme from "@/styles/theme";

interface WrapperProps {
  withImage: boolean;
}

export const Wrapper = styled.main<WrapperProps>`
  min-height: 100vh;
  background-color: ${theme.colors.grey[100]};
  ${({withImage}) =>
    withImage &&
    css`
      background-image: url(${app_background});
      background-position: center;
      background-size: cover;
    `}
`;
