import theme from "@/styles/theme";

const HarmonyPlaza = {
  name: "Harmony Building",
  weather: {
    description: "Partly clouded, Rain and thunder",
    temperature: "10°C",
    icon: '<path d="M8 15L5 19H7V22L10 18H8V15Z" fill="currentColor"/><path d="M6.06994 15.906L8.99994 12V16H13.9999L16.9999 12V15.705C18.0886 15.3805 19.082 14.796 19.8943 14.0018C20.7066 13.2077 21.3134 12.2278 21.6624 11.1467C22.0113 10.0656 22.0919 8.91583 21.8972 7.79663C21.7024 6.67744 21.2383 5.62245 20.5447 4.72275C19.8511 3.82305 18.949 3.10566 17.9162 2.63253C16.8834 2.1594 15.751 1.94473 14.6167 2.00707C13.4824 2.0694 12.3803 2.40686 11.4056 2.99032C10.4308 3.57377 9.61276 4.38569 9.02194 5.35597C10.2256 5.96316 11.2495 6.87467 11.9919 7.99997L11.1749 8.57497C9.67795 6.45397 8.39294 5.99997 6.99994 5.99997C5.76073 6.00849 4.56883 6.4769 3.65538 7.31437C2.74193 8.15183 2.17204 9.29868 2.0562 10.5325C1.94037 11.7663 2.28685 12.9992 3.02846 13.992C3.77008 14.9849 4.85397 15.667 6.06994 15.906Z" fill="currentColor"/><path d="M16 15L13 19H15V22L18 18H16V15Z" fill="currentColor"/>',
  },
  currentConsumption: 10.27,
  info: {
    door: true,
    fans: false,
    smoke: true,
    earth: true,
    grid: 38.5,
    pv: false,
  },
  battery: {
    battery: 79.2,
    amper: -0.5,
    volt: 55.0,
    temperature: 18.5,
    power: 5000,
    l1: 240,
    l2: 250,
    l3: 260,
  },
  energyConsumption: [
    {
      energy: 12500,
      consumption: 1200,
      tco2: 4500,
    },
    {
      energy: 17500,
      consumption: 1500,
      tco2: 6500,
    },
    {
      energy: 22000,
      consumption: 1800,
      tco2: 7500,
    },
    {
      energy: 14500,
      consumption: 1300,
      tco2: 5000,
    },
    {
      energy: 27000,
      consumption: 1900,
      tco2: 8500,
    },
    {
      energy: 19500,
      consumption: 1600,
      tco2: 7000,
    },
    {
      energy: 10500,
      consumption: 1100,
      tco2: 4000,
    },
    {
      energy: 28000,
      consumption: 1950,
      tco2: 9000,
    },
    {
      energy: 19500,
      consumption: 1600,
      tco2: 7000,
    },
    {
      energy: 14500,
      consumption: 1300,
      tco2: 5000,
    },
    {
      energy: 22000,
      consumption: 1800,
      tco2: 7500,
    },
    {
      energy: 12500,
      consumption: 1200,
      tco2: 4500,
    },
  ],
  energyConsumptionByYear: [
    [
      {
        energy: 12500,
        consumption: 1200,
        tco2: 4500,
      },
      {
        energy: 17500,
        consumption: 1500,
        tco2: 6500,
      },
      {
        energy: 22000,
        consumption: 1800,
        tco2: 7500,
      },
      {
        energy: 18500,
        consumption: 1600,
        tco2: 7200,
      },
      {
        energy: 11500,
        consumption: 1200,
        tco2: 4500,
      },
      {
        energy: 25500,
        consumption: 1900,
        tco2: 8500,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
    ],
    [
      {
        energy: 18500,
        consumption: 1600,
        tco2: 7200,
      },
      {
        energy: 11500,
        consumption: 1200,
        tco2: 4500,
      },
      {
        energy: 25500,
        consumption: 1900,
        tco2: 8500,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    [
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
    ],
  ],
  emissionActivity: {
    consumption: 450,
    tco2: 400,
    energy: 200,
    scope1: 150,
    scope2: 100,
    scope3: 50,
  },
  scope1: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumption", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  scope2: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumption", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  scope3: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumption", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  settings: {
    dischargePoint: 1000,
    chargePoint: 800,
    maxPower: 14000,
    minPower: 16000,
    chart: [1200, 800, 700, 400, -400, -900, -1100, -300, 400, 1500],
  },
  power: {
    grid: {
      value: 40,
      max: 100,
    },
    shaper: {
      value: 60,
      max: 100,
    },
    load: {
      value: 23,
      max: 100,
    },
  },
  history: [
    {
      batteryVoltage: 46,
      batteryCapacity: 100,
      meterPower: 0,
      storagePower: 96076,
    },
    {
      batteryVoltage: 55,
      batteryCapacity: 90,
      meterPower: 0,
      storagePower: 49331,
    },
    {
      batteryVoltage: 66,
      batteryCapacity: 70,
      meterPower: 3000,
      storagePower: 83433,
    },
    {
      batteryVoltage: 80,
      batteryCapacity: 50,
      meterPower: 3000,
      storagePower: 56370,
    },
    {
      batteryVoltage: 90,
      batteryCapacity: 30,
      meterPower: 3000,
      storagePower: 34332,
    },
    {
      batteryVoltage: 71,
      batteryCapacity: 40,
      meterPower: 5000,
      storagePower: 24274,
    },
    {
      batteryVoltage: 45,
      batteryCapacity: 60,
      meterPower: 5000,
      storagePower: 76142,
    },
    {
      batteryVoltage: 65,
      batteryCapacity: 80,
      meterPower: 3000,
      storagePower: 2778,
    },
    {
      batteryVoltage: 53,
      batteryCapacity: 70,
      meterPower: 4000,
      storagePower: 67675,
    },
    {
      batteryVoltage: 50,
      batteryCapacity: 65,
      meterPower: 2000,
      storagePower: 7223,
    },
  ],
};

export default HarmonyPlaza;
