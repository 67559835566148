import React from "react";
import Card, {CardHeader} from "../ui/card";
import {
  Content,
  InfoDescription,
  InfoValue,
  InfoValueIcon,
  InfoWrapper,
} from "./cabinetStatus.style";

interface GabinetStatusProps {
  door: boolean;
  fans: boolean;
  smoke: boolean;
  earth: boolean;
  grid: number;
  pv: boolean;
}

const CabinetStatus = ({
  door,
  fans,
  smoke,
  earth,
  grid,
  pv,
}: GabinetStatusProps): JSX.Element => {
  return (
    <Card>
      <CardHeader>Cabinet Status</CardHeader>
      <Content>
        <InfoWrapper>
          <InfoDescription>Door:</InfoDescription>
          <InfoValue positive={door}>
            <InfoValueIcon positive={door} />
            {door ? "Closed" : "Open"}
          </InfoValue>
        </InfoWrapper>
        <InfoWrapper>
          <InfoDescription>Fans:</InfoDescription>
          <InfoValue positive={fans}>
            <InfoValueIcon positive={fans} />
            {fans ? "Working" : "Not working"}
          </InfoValue>
        </InfoWrapper>
        <InfoWrapper>
          <InfoDescription>Smoke det:</InfoDescription>
          <InfoValue positive={smoke}>
            <InfoValueIcon positive={smoke} />
            {smoke ? "Working" : "Not working"}
          </InfoValue>
        </InfoWrapper>
        <InfoWrapper>
          <InfoDescription>PV:</InfoDescription>
          <InfoValue positive={pv}>
            <InfoValueIcon positive={pv} />
            {pv ? "Working" : "Not working"}
          </InfoValue>
        </InfoWrapper>
        <InfoWrapper>
          <InfoDescription>Earth Fault:</InfoDescription>
          <InfoValue positive={earth}>
            <InfoValueIcon positive={earth} />
            {earth ? "Working" : "Not working"}
          </InfoValue>
        </InfoWrapper>
        <InfoWrapper>
          <InfoDescription>Grid:</InfoDescription>
          <InfoValue positive={grid > 0}>
            <InfoValueIcon positive={grid > 0} />
            {grid} kW
          </InfoValue>
        </InfoWrapper>
      </Content>
    </Card>
  );
};

export default CabinetStatus;
