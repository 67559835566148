import React from "react";
import {Stack, Wrapper} from "./battery.style";

interface Props {
  value: number;
  className?: string;
}

const Battery = ({value, className}: Props): JSX.Element => {
  const stackSize = 5;
  return (
    <Wrapper className={className}>
      {Array.from({length: stackSize}).map((v, key) => {
        return <Stack fill={(100 / stackSize) * key + 1 < value} key={key} />;
      })}
    </Wrapper>
  );
};

export default Battery;
