import styled from "styled-components";
import {CardContent, CardGlass} from "../ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";

export const Wrapper = styled(CardGlass)``;

export const Content = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(16)};
  height: 100%;
`;

export const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(16)};
  margin-top: ${pxToRem(8)};
`;

interface TextProps {
  primary: boolean;
}

export const Text = styled.span<TextProps>`
  font-size: ${fluidSize(12, 14)};
  font-weight: 700;
  color: ${props =>
    props.primary ? theme.colors.lightGreen[400] : theme.colors.grey[500]};
`;

export const BatteryWrapper = styled.div`
  max-width: 64px;
  width: 100%;
`;
