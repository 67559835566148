import React, {useContext} from "react";
import {Content} from "./dashboard.style";
import Layout from "@/components/layout/layout";
import EmissionByYear from "@/components/emissionByYear/emissionByYear";
import {ProjectContext} from "@/contexts/projectContext";
import EmissionActivity from "@/components/emissionActivity/emissionActivity";
import ScopeBox from "@/components/scopeBox/scopeBox";
import EmissionByMonth from "@/components/emissionByMonth/emissionByMonth";
import FadeIn from "@/components/ui/fadeIn/fadeIn";

const Dashboard = (): JSX.Element => {
  const {project} = useContext(ProjectContext);

  return (
    <Layout home={false} active="dashboard">
      <FadeIn wrapperTag={Content}>
        <EmissionByYear
          values={project.energyConsumptionByYear
            .map((yearEmission, key) => {
              return {
                year: (2023 - key).toString(),
                emission: yearEmission.reduce((sum, monthEmission) => {
                  return monthEmission.tco2 + sum;
                }, 0),
              };
            })
            .reverse()}
        />
        <EmissionByMonth
          values={project.energyConsumptionByYear
            .map((yearEmission, key) => {
              return {
                year: (2023 - key).toString(),
                emission: yearEmission,
              };
            })
            .reverse()}
        />
        <EmissionActivity />
        <ScopeBox title="Scope 1" {...project.scope1} />
        <ScopeBox title="Scope 2" {...project.scope2} />
        <ScopeBox title="Scope 3" {...project.scope3} />
      </FadeIn>
    </Layout>
  );
};

export default Dashboard;
