import React from "react";
import {
  Content,
  InfoCard,
  InfoDescription,
  InfoValue,
  InfoValueIcon,
  Wrapper,
} from "./localizationInfo.style";

interface LocalizationInfoProps {
  door: boolean;
  fans: boolean;
  smoke: boolean;
  earth: boolean;
  grid: number;
  pv: boolean;
}

const LocalizationInfo = ({
  door,
  fans,
  smoke,
  earth,
  grid,
  pv,
}: LocalizationInfoProps): JSX.Element => {
  return (
    <Wrapper>
      <InfoCard>
        <Content>
          <InfoDescription>Door:</InfoDescription>
          <InfoValue positive={door}>
            <InfoValueIcon positive={door} />
            {door ? "Closed" : "Open"}
          </InfoValue>
        </Content>
      </InfoCard>

      <InfoCard>
        <Content>
          <InfoDescription>Fans:</InfoDescription>
          <InfoValue positive={fans}>
            <InfoValueIcon positive={fans} />
            {fans ? "Working" : "Not Working"}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>Grid:</InfoDescription>
          <InfoValue positive={grid > 0}>
            <InfoValueIcon positive={grid > 0} />
            {grid} kW
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>PV:</InfoDescription>
          <InfoValue positive={pv}>
            <InfoValueIcon positive={pv} />
            {pv ? "Working" : "Not Working"}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>Smoke det:</InfoDescription>
          <InfoValue positive={smoke}>
            <InfoValueIcon positive={smoke} />
            {smoke ? "Working" : "Not Working"}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>Earth fault:</InfoDescription>
          <InfoValue positive={earth}>
            <InfoValueIcon positive={earth} />
            {earth ? "Working" : "Not Working"}
          </InfoValue>
        </Content>
      </InfoCard>
    </Wrapper>
  );
};

export default LocalizationInfo;
