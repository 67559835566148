import React from "react";
import ReactDOM from "react-dom/client";
import {RouterProvider} from "react-router-dom";
import router from "@/router";
import {ThemeProvider} from "styled-components";
import theme from "@/styles/theme";
import GlobalStyle from "@/styles/globalStyles";
import "@fontsource/instrument-sans";
import "@fontsource/instrument-sans/400.css";
import "@fontsource/instrument-sans/500.css";
import "@fontsource/instrument-sans/600.css";
import "@fontsource/instrument-sans/700.css";
import {ProjectProvider} from "./contexts/projectContext";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root");
if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ProjectProvider>
          <GlobalStyle />
          <RouterProvider router={router} />
        </ProjectProvider>
      </ThemeProvider>
    </React.StrictMode>,
  );
}

serviceWorkerRegistration.unregister();
