import {createStyledBreakpointsTheme} from "styled-breakpoints";

const primaryTheme = {
  colors: {
    primary: "#000000",
    white: "#ffffff",
    black: "#1B1B1B",
    link: "#009",
    lightGreen: {
      300: "#7CBD27",
      400: "#669E1D",
    },
    green: {
      100: "#C2E2D2",
      300: "#27B28D",
    },
    lightBlue: {
      100: "#ACDCEA",
      300: "#00A6CB",
      400: "#008CAA",
    },
    blue: {
      300: "#2980C4",
      400: "#206AA4",
    },
    orange: {
      300: "#F08300",
    },
    yellow: {
      300: "#FFDB46",
    },
    grey: {
      100: "#EFEFEF",
      200: "#C9CACA",
      300: "#9FA0A0",
      400: "#727171",
      500: "#525252",
    },
    red: {
      400: "#BA2947",
    },
  },
};

const theme = {
  ...primaryTheme,
  ...createStyledBreakpointsTheme(),
};

export default theme;
