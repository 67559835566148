import React, {type JSXElementConstructor, type PropsWithChildren} from "react";
import styled from "styled-components";
import {fadeInAnimation} from "./fadeIn.style";

interface Props {
  delay?: number;
  transitionDuration?: number;
  wrapperTag?: JSXElementConstructor<any>;
  className?: string;
}

const FadeIn = (props: PropsWithChildren<Props>): JSX.Element => {
  const {delay = 0, transitionDuration = 500} = props;
  const WrapperTag = props.wrapperTag ?? "div";

  const WrapperTagStyled = styled(WrapperTag)`
    opacity: 0;
    animation-name: ${fadeInAnimation};
    animation-duration: ${transitionDuration}ms;
    animation-delay: ${delay}ms;
    animation-fill-mode: forwards;
  `;

  return (
    <WrapperTagStyled {...props} className={props.className}>
      {props.children}
    </WrapperTagStyled>
  );
};

export default FadeIn;
