import Layout from "@/components/layout/layout";
import React, {useContext} from "react";
import {
  HistoryChartLegendCircle,
  HistoryChartLegendItem,
  HistoryChartLegendText,
  HistoryChartLegendWrapper,
  HistoryChartWrapper,
  HistoryWrapper,
  HistoryWrapperTitle,
  InfoWrapper,
  PowerChartWrapper,
  Wrapper,
} from "./data.style";
import Card, {CardHeader} from "@/components/ui/card";
import {ProjectContext} from "@/contexts/projectContext";
import GabinetStatus from "@/components/cabinetStatus/cabinetStatus";
import Sources from "@/components/sources/sources";
import HistoryChart from "@/components/charts/historyChart/historyChart";
import theme from "@/styles/theme";
import PowerChartWithLabel from "@/components/charts/powerChart/powerChartWithLabel";
import FadeIn from "@/components/ui/fadeIn/fadeIn";

const Data = (): JSX.Element => {
  const {project: localization} = useContext(ProjectContext);

  return (
    <Layout home={false} active="data">
      <FadeIn wrapperTag={Wrapper}>
        <Card>
          <CardHeader>Grid power</CardHeader>
          <PowerChartWrapper>
            <PowerChartWithLabel index="1" data={localization.power.grid} />
          </PowerChartWrapper>
        </Card>
        <Card>
          <CardHeader>Power shaper</CardHeader>
          <PowerChartWrapper>
            <PowerChartWithLabel index="2" data={localization.power.shaper} />
          </PowerChartWrapper>
        </Card>
        <Card>
          <CardHeader>Load power</CardHeader>
          <PowerChartWrapper>
            <PowerChartWithLabel index="3" data={localization.power.load} />
          </PowerChartWrapper>
        </Card>
        <InfoWrapper>
          <GabinetStatus {...localization.info} />
          <Sources {...localization.battery} />
        </InfoWrapper>
        <HistoryWrapper>
          <CardHeader>
            <HistoryWrapperTitle>History</HistoryWrapperTitle>
            <HistoryChartLegendWrapper>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle color={theme.colors.lightBlue[300]} />
                <HistoryChartLegendText>Battery Voltage</HistoryChartLegendText>
              </HistoryChartLegendItem>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle
                  color={theme.colors.lightGreen[300]}
                />
                <HistoryChartLegendText>
                  Remaining Battery Capacity
                </HistoryChartLegendText>
              </HistoryChartLegendItem>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle color={theme.colors.green[300]} />
                <HistoryChartLegendText>Meter power</HistoryChartLegendText>
              </HistoryChartLegendItem>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle color={theme.colors.orange[300]} />
                <HistoryChartLegendText>Storage power</HistoryChartLegendText>
              </HistoryChartLegendItem>
            </HistoryChartLegendWrapper>
          </CardHeader>
          <HistoryChartWrapper>
            <HistoryChart
              data={localization.history.map((data, key) => {
                const time = new Date(
                  Date.now() -
                    300000 * localization.history.length +
                    300000 * key,
                );
                return {
                  time:
                    key === localization.history.length - 1
                      ? "NOW"
                      : `${time.getHours()}:${time.getMinutes()}`,
                  ...data,
                };
              })}
            />
          </HistoryChartWrapper>
        </HistoryWrapper>
      </FadeIn>
    </Layout>
  );
};

export default Data;
