import React from "react";
import {
  ButtonText,
  Input,
  InputWrapper,
  Label,
  Wrapper,
} from "./numberInput.style";
import {ContainedButton, OutlinedButton} from "../button";

interface Props {
  color: string;
  step: number;
  min?: number;
  max?: number;
  label: string;
  onChange?: (value: number) => void;
  value: number;
}

const NumberInput = ({
  color,
  min,
  max,
  step,
  label,
  value,
  onChange = value => {},
}: Props): JSX.Element => {
  const changeValue = (diff: number): void => {
    if (checkDisabled(diff)) return;
    onChange(value + diff);
  };

  const checkDisabled = (diff: number): boolean => {
    const newValue = value + diff;
    if (min !== undefined && newValue < min) return true;
    if (max !== undefined && newValue > max) return true;
    return false;
  };

  const changeValueDirectly = (value: string): void => {
    const valueAsNumber = parseInt(value);
    if (!isNaN(valueAsNumber)) {
      if (min !== undefined && valueAsNumber < min) return;
      if (max !== undefined && valueAsNumber > max) return;
      onChange(valueAsNumber);
    } else {
      onChange(0);
    }
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputWrapper>
        <OutlinedButton
          color={color}
          disabled={checkDisabled.bind(this)(-100)}
          onClick={() => {
            changeValue(-step);
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentcolor">
            <path d="M19 13H5V11H19V13Z" fill="currentcolor" />
          </svg>
          <ButtonText>{step}</ButtonText>
        </OutlinedButton>
        <ContainedButton
          disabled={checkDisabled.bind(this)(-1)}
          onClick={() => {
            changeValue(-1);
          }}
          color={color}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentcolor">
            <path d="M19 13H5V11H19V13Z" fill="currentcolor" />
          </svg>
        </ContainedButton>
        <Input
          color={color}
          onChange={e => {
            changeValueDirectly(e.target.value);
          }}
          type="text"
          value={value}
        />
        <ContainedButton
          onClick={() => {
            changeValue(+1);
          }}
          color={color}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentcolor">
            <path
              d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              fill="currentcolor"
            />
          </svg>
        </ContainedButton>
        <OutlinedButton
          onClick={() => {
            changeValue(+100);
          }}
          color={color}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentcolor">
            <path
              d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              fill="currentcolor"
            />
          </svg>
          <ButtonText>{step}</ButtonText>
        </OutlinedButton>
      </InputWrapper>
    </Wrapper>
  );
};

export default NumberInput;
