import React, {useState} from "react";
import {ArrowButton, ValueText, Wrapper} from "./horizontalSelect.style";

interface Props {
  values: string[];
  defaultIndex?: number;
  onChange: (index: number, value: string) => void;
}

const HorizontalSelect = ({
  values,
  defaultIndex,
  onChange,
}: Props): JSX.Element => {
  const [index, setIndex] = useState(defaultIndex ?? 0);

  const previousValue = (): void => {
    if (index > 0) {
      setIndex(index - 1);
      onChange(index - 1, values[index - 1]);
    }
  };

  const nextValue = (): void => {
    if (index < values.length) {
      setIndex(index + 1);
      onChange(index + 1, values[index + 1]);
    }
  };

  return (
    <Wrapper>
      <ArrowButton onClick={previousValue} disabled={index === 0}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M14.2951 18L15.7051 16.59L11.1251 12L15.7051 7.41L14.2951 6L8.29508 12L14.2951 18Z"
            fill="#727171"
          />
        </svg>
      </ArrowButton>
      <ValueText>{values[index]}</ValueText>
      <ArrowButton onClick={nextValue} disabled={index === values.length - 1}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z"
            fill="#727171"
          />
        </svg>
      </ArrowButton>
    </Wrapper>
  );
};

export default HorizontalSelect;
