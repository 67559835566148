import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(201, 202, 202, 0.32);
  background: linear-gradient(
    180deg,
    rgba(159, 160, 160, 0.08) 4.17%,
    rgba(255, 255, 255, 0.08) 100%,
    rgba(239, 239, 239, 0.08) 100%
  );
  display: flex;
  align-items: center;
  gap: ${pxToRem(8)};
  padding: ${pxToRem(4)} ${pxToRem(8)} ${pxToRem(4)} ${pxToRem(8)};
`;

export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  margin: 0;
  padding: 0;

  &:disabled {
    opacity: 0.2;
  }
`;

export const ValueText = styled.span`
  font-size: ${fluidSize(10, 12)};
  color: ${theme.colors.grey[400]};
`;
