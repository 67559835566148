import React, {useContext, useEffect, useState} from "react";
import {
  Column,
  Consumption,
  ConsumptionDescription,
  ConsumptionIconWrapper,
  Content,
  EmissionMore,
  EmmisionCardContent,
  EmmisionChartWrapper,
  InfoColumn,
  LocalizationName,
  Row,
  Temperature,
  TemperatureIcon,
  WeatherDescription,
} from "./index.style";
import {
  CardGlass,
  CardHeader,
  CardSubTitle,
  CardTitle,
} from "@/components/ui/card";
import Layout from "@/components/layout/layout";
import EmissionChart from "@/components/charts/emissionChart/emissionChart";
import type {ChartData} from "@/components/charts/emissionChart/emissionChart";
import LocalizationInfo from "@/components/localizationInfo/localizationInfo";
import {ProjectContext} from "@/contexts/projectContext";
import BatteryCard from "@/components/batteryCard/batteryCard";
import FadeIn from "@/components/ui/fadeIn/fadeIn";

const Index = (): JSX.Element => {
  const {project: localization} = useContext(ProjectContext);
  const [emissionChartData, setEmissionChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    const newData = localization.energyConsumption.map((consumption, key) => {
      return {
        name: (key + 1).toString(),
        ...consumption,
      };
    });
    setEmissionChartData(newData);
  }, [localization]);

  return (
    <Layout home={true}>
      <FadeIn wrapperTag={Content}>
        <Column>
          <CardGlass>
            <CardHeader>
              <CardTitle>
                CO<sup>2</sup> Emission
              </CardTitle>
              <CardSubTitle>Last two weeks</CardSubTitle>
              <EmissionMore to="/dashboard">
                See more
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M6.47003 4L5.53003 4.94L8.58336 8L5.53003 11.06L6.47003 12L10.47 8L6.47003 4Z"
                    fill="#000099"
                  />
                </svg>
              </EmissionMore>
            </CardHeader>
            <EmmisionCardContent>
              <EmmisionChartWrapper>
                <EmissionChart data={emissionChartData} legend={false} />
              </EmmisionChartWrapper>
            </EmmisionCardContent>
          </CardGlass>

          <Row>
            <BatteryCard {...localization.battery} />
            <LocalizationInfo {...localization.info} />
          </Row>
        </Column>
        <InfoColumn>
          <Temperature>
            <TemperatureIcon
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              dangerouslySetInnerHTML={{
                __html: localization.weather.icon,
              }}></TemperatureIcon>
            {localization.weather.temperature}
          </Temperature>
          <WeatherDescription>
            {localization.weather.description}
          </WeatherDescription>
          <LocalizationName>{localization.name}</LocalizationName>
          <ConsumptionDescription>Current consumption:</ConsumptionDescription>
          <Consumption>
            <ConsumptionIconWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none">
                <path
                  d="M5.33341 16L7.21341 14.12L14.6667 21.56V5.33337H17.3334V21.56L24.7734 14.1067L26.6667 16L16.0001 26.6667L5.33341 16Z"
                  fill="#D2E6AE"
                />
              </svg>
            </ConsumptionIconWrapper>
            {localization.currentConsumption} kW
          </Consumption>
        </InfoColumn>
      </FadeIn>
    </Layout>
  );
};

export default Index;
