import React from "react";
import {createBrowserRouter} from "react-router-dom";
import {Dashboard, Data, Index, Settings, NotFound} from "@/pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/data",
    element: <Data />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

export default router;
