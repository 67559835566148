import Card, {CardContent, CardTitle} from "@/components/ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: ${pxToRem(32)};
  padding-bottom: ${pxToRem(32)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 0.58fr 1.2fr;
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr; */
  min-height: calc(100vh - 96px - 64px);
  column-gap: ${pxToRem(16)};
  row-gap: ${pxToRem(16)};

  ${({theme}) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;

export const PowerChartWrapper = styled(CardContent)`
  padding-bottom: 0;
  padding-top: 0;
  max-height: ${pxToRem(180)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${pxToRem(16)};
`;

export const HistoryWrapper = styled(Card)`
  grid-column-start: 2;
  grid-column-end: 4;
`;

export const HistoryWrapperTitle = styled(CardTitle)`
  font-weight: 600;
`;

export const HistoryChartWrapper = styled(CardContent)`
  padding-left: 0;
  flex: 1;
  height: 100%;
  max-height: 420px;
`;

export const HistoryChartLegendWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: ${pxToRem(16)};
`;

export const HistoryChartLegendItem = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
`;

interface HistoryChartLegendCircleProps {
  color: string;
}

export const HistoryChartLegendCircle = styled.span<HistoryChartLegendCircleProps>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${props => props.color};
`;

export const HistoryChartLegendText = styled.span`
  font-size: ${fluidSize(8, 10)};
  color: ${theme.colors.grey[500]};
`;
