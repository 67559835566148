import styled from "styled-components";
import {CardContent} from "../ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import Battery from "../battery/battery";

export const Content = styled(CardContent)`
  padding-top: 0;
  display: grid;
  grid-template-columns: 2fr 0.8fr;
  gap: ${pxToRem(16)};
`;

export const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(16)};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.span`
  font-size: ${fluidSize(12, 14)};
  color: ${theme.colors.grey[500]};
`;

export const InfoValue = styled.span`
  font-size: ${fluidSize(12, 14)};
  color: ${theme.colors.grey[500]};
  font-weight: 700;
`;

export const BatteryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  justify-content: end;
  align-items: end;
`;

export const BatteryTitle = styled.span`
  font-size: ${fluidSize(12, 14)};
  color: ${theme.colors.lightGreen[400]};
  font-weight: 700;
`;

export const BatteryComponent = styled(Battery)`
  flex: 1;
  width: 100px;
`;
