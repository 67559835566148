import {ProjectContext} from "@/contexts/projectContext";
import React, {useContext} from "react";
import Dropdown from "../dropdown/dropdown";

const LocalizationDropdown = (): JSX.Element => {
  const {project, projects, setProject} = useContext(ProjectContext);
  return (
    <Dropdown<typeof project>
      options={projects.map((project, key) => {
        return {
          value: project,
          text: project.name,
        };
      })}
      defaultValue={{value: project, text: project.name}}
      onChange={option => {
        setProject(option.value);
      }}
    />
  );
};

export default LocalizationDropdown;
