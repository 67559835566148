import theme from "@/styles/theme";

const EmeraldHavenTower = {
  name: "Emerald Haven Tower",
  weather: {
    description: "Partly clouded, Raining",
    temperature: "16°C",
    icon: '<path d="M5.375 21.084L7.375 18.084L8.624 18.916L6.624 21.916L5.375 21.084ZM9.375 21.084L11.375 18.084L12.624 18.916L10.624 21.916L9.375 21.084ZM13.375 21.084L15.375 18.084L16.624 18.915L14.624 21.915L13.375 21.084ZM15.1 7.5C14.3146 7.50099 13.5413 7.69316 12.8469 8.05991C12.1524 8.42666 11.5577 8.95695 11.114 9.605C12.0518 9.97815 12.8196 10.6833 13.271 11.586C13.257 11.597 12.452 12.179 12.422 12.199C11.374 10.682 10.475 10.356 9.5 10.356C8.56728 10.3781 7.68019 10.7641 7.02833 11.4316C6.37646 12.099 6.01153 12.995 6.01153 13.928C6.01153 14.861 6.37646 15.757 7.02833 16.4244C7.68019 17.0919 8.56728 17.4779 9.5 17.5H15.1C16.4261 17.5 17.6979 16.9732 18.6355 16.0355C19.5732 15.0979 20.1 13.8261 20.1 12.5C20.1 11.1739 19.5732 9.90215 18.6355 8.96447C17.6979 8.02678 16.4261 7.5 15.1 7.5ZM7 2.5H9V4.5H7V2.5ZM2.379 5.221L3.794 3.808L5.207 5.222L3.793 6.636L2.379 5.221ZM1 8.5H3V10.5H1V8.5ZM10.864 5.292L12.279 3.879L13.692 5.293L12.278 6.707L10.864 5.292ZM9.5 8.356H9.547C9.94765 7.80847 10.4264 7.32263 10.968 6.914C10.3236 6.15888 9.41907 5.67327 8.43361 5.55333C7.44816 5.43339 6.45359 5.68787 5.64684 6.26636C4.84009 6.84486 4.27997 7.7052 4.0774 8.67704C3.87482 9.64888 4.04456 10.6614 4.553 11.514C4.99468 10.5738 5.69387 9.77803 6.56947 9.21907C7.44508 8.66011 8.46121 8.36085 9.5 8.356Z" fill="currentColor"/>',
  },
  currentConsumption: 23.4,
  info: {
    door: true,
    fans: true,
    smoke: true,
    earth: true,
    grid: 0,
    pv: false,
  },
  battery: {
    battery: 30.1,
    amper: -1.5,
    volt: 85.0,
    temperature: 15.5,
    power: 5000,
    l1: 240,
    l2: 250,
    l3: 260,
  },
  energyConsumption: [
    {
      energy: 25500,
      consumption: 1900,
      tco2: 8500,
    },
    {
      energy: 16500,
      consumption: 1550,
      tco2: 6700,
    },
    {
      energy: 24500,
      consumption: 1850,
      tco2: 8200,
    },
    {
      energy: 15500,
      consumption: 1450,
      tco2: 6200,
    },
    {
      energy: 20500,
      consumption: 1700,
      tco2: 7400,
    },
    {
      energy: 28500,
      consumption: 2000,
      tco2: 9500,
    },
    {
      energy: 11500,
      consumption: 1250,
      tco2: 4700,
    },
    {
      energy: 29500,
      consumption: 2050,
      tco2: 9800,
    },
    {
      energy: 17500,
      consumption: 1650,
      tco2: 7300,
    },
    {
      energy: 14500,
      consumption: 1500,
      tco2: 6000,
    },
    {
      energy: 26500,
      consumption: 1950,
      tco2: 8700,
    },
    {
      energy: 17500,
      consumption: 1600,
      tco2: 7200,
    },
  ],
  energyConsumptionByYear: [
    [
      {
        energy: 19500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    [
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
    ],
    [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
  ],
  emissionActivity: {
    consumption: 1200,
    tco2: 550,
    energy: 100,
    scope1: 70,
    scope2: 200,
    scope3: 20,
  },
  scope1: {
    thisMonth: 23.74,
    lastMonth: 56.53,
    emissions: [
      {
        energy: 25500,
        consumption: 1100,
        tco2: 7200,
      },
      {
        energy: 25500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 13500,
        consumption: 1450,
        tco2: 4600,
      },
      {
        energy: 19500,
        consumption: 3050,
        tco2: 5800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 9500,
        consumption: 550,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumtpion", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  scope2: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumtpion", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  scope3: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {name: "consumtpion", value: 35, color: theme.colors.blue[300]},
      {name: "tco2", value: 65, color: theme.colors.lightBlue[300]},
      {name: "energy", value: 25, color: theme.colors.lightGreen[300]},
    ],
  },
  settings: {
    dischargePoint: 500,
    chargePoint: 700,
    maxPower: 9000,
    minPower: 5000,
    chart: [-300, -800, -1200, -400, 200, 400, 700, 900, 500, 400],
  },
  power: {
    grid: {
      value: 10,
      max: 100,
    },
    shaper: {
      value: 47,
      max: 100,
    },
    load: {
      value: 41,
      max: 100,
    },
  },
  history: [
    {
      batteryVoltage: 52,
      batteryCapacity: 72,
      meterPower: 1527,
      storagePower: 25971,
    },
    {
      batteryVoltage: 82,
      batteryCapacity: 27,
      meterPower: 7204,
      storagePower: 68213,
    },
    {
      batteryVoltage: 14,
      batteryCapacity: 25,
      meterPower: 7358,
      storagePower: 44736,
    },
    {
      batteryVoltage: 6,
      batteryCapacity: 90,
      meterPower: 9875,
      storagePower: 51565,
    },
    {
      batteryVoltage: 85,
      batteryCapacity: 3,
      meterPower: 5597,
      storagePower: 21461,
    },
    {
      batteryVoltage: 45,
      batteryCapacity: 22,
      meterPower: 2225,
      storagePower: 43872,
    },
    {
      batteryVoltage: 38,
      batteryCapacity: 90,
      meterPower: 6447,
      storagePower: 97135,
    },
    {
      batteryVoltage: 26,
      batteryCapacity: 9,
      meterPower: 8812,
      storagePower: 67235,
    },
    {
      batteryVoltage: 80,
      batteryCapacity: 69,
      meterPower: 4311,
      storagePower: 6265,
    },
    {
      batteryVoltage: 46,
      batteryCapacity: 30,
      meterPower: 2331,
      storagePower: 39274,
    },
    {
      batteryVoltage: 70,
      batteryCapacity: 31,
      meterPower: 3643,
      storagePower: 94177,
    },
  ],
};

export default EmeraldHavenTower;
