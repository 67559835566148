import {pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${pxToRem(5)};
`;

interface StackProps {
  fill: boolean;
}

export const Stack = styled.div<StackProps>`
  width: 100%;
  border: 1px solid ${theme.colors.lightGreen[400]};
  flex: 1;
  min-height: 10px;
  &:first-child {
    border-radius: 0 0 8px 8px;
  }
  &:last-child {
    border-radius: 8px 8px 0 0;
  }
  transition: all 0.3s;
  background-color: ${props =>
    props.fill ? theme.colors.lightGreen[400] : "transparent"};
`;
