import theme from "@/styles/theme";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizedTick from "./customizedTick";
import CustomizedXTick from "./customizedXTick";

export interface HistoryChartData {
  time: string;
  batteryVoltage: number;
  batteryCapacity: number;
  meterPower: number;
  storagePower: number;
}

interface Props {
  data: HistoryChartData[];
}

const HistoryChart = ({data}: Props): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          axisLine={false}
          tickLine={false}
          tick={<CustomizedXTick />}
        />
        <YAxis
          width={40}
          tick={
            <CustomizedTick
              color={theme.colors.lightBlue[300]}
              textAnchor="end"
            />
          }
          yAxisId="batteryVoltageYAxis"
          domain={[0, 100]}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          width={40}
          tick={
            <CustomizedTick
              color={theme.colors.lightGreen[300]}
              percent={true}
              textAnchor="end"
            />
          }
          yAxisId="batteryCapacityYAxis"
          domain={[0, 100]}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          width={50}
          tick={<CustomizedTick color={theme.colors.green[300]} />}
          yAxisId="meterPowerYAxis"
          orientation="right"
          domain={[0, 10000]}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          width={30}
          tick={<CustomizedTick color={theme.colors.orange[300]} />}
          yAxisId="storagePowerYAxis"
          orientation="right"
          domain={[0, 100000]}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip contentStyle={{borderRadius: "8px"}} />
        <Line
          dataKey="batteryVoltage"
          stroke={theme.colors.lightBlue[300]}
          strokeLinecap="round"
          strokeWidth={2}
          dot={{strokeWidth: 1, fill: theme.colors.lightBlue[300]}}
          yAxisId="batteryVoltageYAxis"
        />
        <Line
          yAxisId="batteryCapacityYAxis"
          dataKey="batteryCapacity"
          strokeLinecap="round"
          strokeWidth={2}
          dot={{strokeWidth: 1, fill: theme.colors.lightGreen[300]}}
          stroke={theme.colors.lightGreen[300]}
        />
        <Line
          type="step"
          yAxisId="meterPowerYAxis"
          dataKey="meterPower"
          strokeLinecap="round"
          strokeWidth={2}
          dot={{strokeWidth: 1, fill: theme.colors.green[300]}}
          stroke={theme.colors.green[300]}
        />
        <Line
          yAxisId="storagePowerYAxis"
          dataKey="storagePower"
          strokeLinecap="round"
          strokeWidth={2}
          dot={{strokeWidth: 1, fill: theme.colors.orange[300]}}
          stroke={theme.colors.orange[300]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HistoryChart;
