const calculateDiffrenceInPercent = (
  lastYear: number,
  thisYear: number,
): number => {
  const percent = (100 * (lastYear - thisYear)) / lastYear;
  if (percent > 100) return percent - 100;

  return -percent;
};

export default calculateDiffrenceInPercent;
