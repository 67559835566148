import FadeIn from "@/components/ui/fadeIn/fadeIn";
import localizationsData from "@/localizationsData";
import type HarmonyPlaza from "@/localizationsData/harmonyplaza";
import React, {createContext, useState} from "react";
import styled from "styled-components";

const FadeInWrapper = styled.div``;

export interface ProjectContextType {
  projects: Array<typeof HarmonyPlaza>;
  project: typeof HarmonyPlaza;
  setProject: (project: typeof HarmonyPlaza) => void;
}

const ProjectContextInstance = {
  projects: localizationsData,
  project: localizationsData[0],
};

export const ProjectContext = createContext<ProjectContextType>(
  ProjectContextInstance as ProjectContextType,
);

export const ProjectProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const projects = localizationsData;
  const [project, setProject] = useState<typeof HarmonyPlaza>(
    localizationsData[0],
  );

  return (
    <ProjectContext.Provider value={{projects, project, setProject}}>
      <FadeIn wrapperTag={FadeInWrapper}>{children}</FadeIn>
    </ProjectContext.Provider>
  );
};
