import React, {useContext} from "react";
import {
  ActivityChartWrapper,
  Content,
  Legend,
  LegendCircle,
  LegendItem,
  LegendText,
  Wrapper,
} from "./emissionActivity.style";
import {CardHeader, CardSubTitle, CardTitle} from "../ui/card";
import ActivityChart from "../charts/activityChart/activityChart";
import {ProjectContext} from "@/contexts/projectContext";
import theme from "@/styles/theme";

interface Props {
  className?: string;
}

const EmissionActivity = ({className}: Props): JSX.Element => {
  const {project} = useContext(ProjectContext);
  const activityData = [
    {
      name: "consumption",
      text: "Consumption",
      color: theme.colors.blue[300],
      value: project.emissionActivity.consumption,
    },
    {
      name: "tco2",
      text: "tCO<sup>2</sup>",
      color: theme.colors.lightBlue[300],
      value: project.emissionActivity.tco2,
    },
    {
      name: "energy",
      text: "Energy",
      color: theme.colors.lightGreen[300],
      value: project.emissionActivity.energy,
    },
    {
      name: "scope1",
      text: "Scope1",
      color: theme.colors.green[300],
      value: project.emissionActivity.scope1,
    },
    {
      name: "scope2",
      text: "Scope2",
      color: theme.colors.orange[300],
      value: project.emissionActivity.scope2,
    },
    {
      name: "scope3",
      text: "Scope3",
      color: theme.colors.yellow[300],
      value: project.emissionActivity.scope3,
    },
  ]
    .sort((a, b) => {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    })
    .map((value, key) => {
      return {
        key,
        ...value,
      };
    });

  return (
    <Wrapper className={className}>
      <CardHeader>
        <CardTitle>
          CO<sub>2</sub> Emission
        </CardTitle>
        <CardSubTitle>Activity</CardSubTitle>
      </CardHeader>
      <Content>
        <ActivityChartWrapper>
          <ActivityChart data={activityData} />
        </ActivityChartWrapper>
        <Legend>
          {activityData.map((data, key) => {
            return (
              <LegendItem key={key}>
                <LegendCircle color={data.color} />
                <LegendText dangerouslySetInnerHTML={{__html: data.text}} />
              </LegendItem>
            );
          })}
        </Legend>
      </Content>
    </Wrapper>
  );
};

export default EmissionActivity;
