import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import PieCell from "./pieCell";
import ActivityChartTooltip from "./activityChartTooltip";

export interface ActivityChartData {
  value: number;
  name: string;
  color: string;
}

interface Props {
  data: ActivityChartData[];
}

const ActivityChart = ({data}: Props): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart margin={{top: 0, left: 0, right: 0, bottom: 0}}>
        <Pie
          outerRadius={65}
          innerRadius={30}
          data={data}
          dataKey="value"
          nameKey="activity"
          activeIndex={0}
          activeShape={<PieCell />}
          inactiveShape={<PieCell />}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<ActivityChartTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ActivityChart;
