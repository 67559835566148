import React from "react";

const ReferenceLineLabel = (props: any): JSX.Element => {
  const {viewBox, stroke} = props;
  const {x, y, width} = viewBox;

  return (
    <svg x={x + width} y={y - 16} fill={stroke} fontSize={10}>
      <rect width="32" height="32" rx="16" fill={stroke} />
      <g clipPath="url(#clip0_325_2558)">
        <path
          d="M19.4933 13.18L20.3467 12.1533L16 8.54663L11.6533 12.1533L12.5067 13.18L16 10.28L19.4933 13.18ZM15.3333 12.6666L15.3333 14L16.6667 14L16.6667 12.6666L15.3333 12.6666ZM16.6667 19.3333L16.6667 18L15.3333 18L15.3333 19.3333L16.6667 19.3333ZM15.3333 15.3333L15.3333 16.6666L16.6667 16.6666L16.6667 15.3333L15.3333 15.3333ZM20.3467 19.8466L19.4933 18.82L16 21.72L12.5067 18.82L11.6533 19.8466L16 23.4533L20.3467 19.8466Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_325_2558">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(24 8) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReferenceLineLabel;
