import React, {useState} from "react";
import {Input, Wrapper, Label} from "./checkboxInput.style";

interface Props {
  label: string;
  defaultChecked: boolean;
  color: string;
}

const CheckboxInput = ({label, defaultChecked, color}: Props): JSX.Element => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = (): void => {
    setChecked(!checked);
  };

  return (
    <Wrapper onClick={handleChange}>
      <Input color={color} type="checkbox" checked={checked} />
      <Label>{label}</Label>
    </Wrapper>
  );
};

export default CheckboxInput;
