import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled, {css} from "styled-components";

interface Props {
  positive: boolean;
}

export const ValueIndicator = styled.span<Props>`
  font-size: ${fluidSize(16, 18)};
  color: ${props =>
    props.positive ? theme.colors.lightGreen[400] : theme.colors.red[400]};
  font-weight: 700;
  &:before {
    width: 16px;
    height: 16px;
    margin-right: ${pxToRem(4)};
    ${({positive}) =>
      positive &&
      css`
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M2.66658 8.00008L3.60658 7.06008L7.33325 10.7801V2.66675H8.66658V10.7801L12.3866 7.05342L13.3333 8.00008L7.99992 13.3334L2.66658 8.00008Z" fill="%23669E1D"/></svg>');
      `}
    ${({positive}) =>
      !positive &&
      css`
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M13.3334 7.99992L12.3934 8.93992L8.66675 5.21992L8.66675 13.3333L7.33342 13.3333L7.33342 5.21992L3.61342 8.94659L2.66675 7.99992L8.00008 2.66659L13.3334 7.99992Z" fill="%23BA2947"/></svg>');
      `}
  }
`;
