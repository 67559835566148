import styled from "styled-components";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import {Link} from "react-router-dom";
import {CardContent} from "@/components/ui/card";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({theme}) => theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr;
  }
  align-items: end;
  min-height: calc(100vh - 96px - 81px);
  padding-bottom: ${pxToRem(80)};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  ${theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
  gap: ${pxToRem(40)};
  margin-top: ${pxToRem(40)};
`;

export const Column = styled.div`
  ${({theme}) => theme.breakpoints.down("xl")} {
    order: 1;
  }
`;

export const InfoColumn = styled.div`
  color: ${theme.colors.white};
  text-align: right;
`;

export const LocalizationName = styled.h1`
  font-size: ${fluidSize(32, 40)};
  font-weight: 400;
  margin-top: ${pxToRem(32)};
  margin-bottom: ${pxToRem(128)};
`;

export const Temperature = styled.h2`
  font-size: ${fluidSize(44, 56)};
  font-weight: 700;
  margin: 0;
`;

export const TemperatureIcon = styled.svg`
  margin-right: ${pxToRem(16)};
`;

export const WeatherDescription = styled.p`
  margin-top: ${pxToRem(8)};
  margin-bottom: ${pxToRem(49)};
  font-size: ${fluidSize(20, 24)};
  color: ${theme.colors.grey[100]};
`;

export const ConsumptionDescription = styled.p`
  font-size: ${fluidSize(20, 24)};
  color: ${theme.colors.grey[100]};
  margin-bottom: ${pxToRem(10)};
  margin-top: ${pxToRem(44)};
`;

export const Consumption = styled.p`
  font-size: ${fluidSize(58, 88)};
  font-weight: 700;
  color: ${theme.colors.lightGreen[400]};
  display: inline-flex;
  align-items: center;
  margin: 0;
`;

export const ConsumptionIconWrapper = styled.div`
  display: inline-flex;
  background-color: ${theme.colors.lightGreen[400]};
  border-radius: 50%;
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  align-items: center;
  justify-content: center;
  margin-right: ${pxToRem(24)};
`;

export const EmmisionCardContent = styled(CardContent)`
  padding-left: 0;
`;
export const EmissionMore = styled(Link)`
  color: ${theme.colors.link};
  font-size: ${fluidSize(8, 12)};
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  text-decoration: none;
`;

export const EmmisionChartWrapper = styled.div`
  width: 100%;
  height: 220px;
`;
