import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface ArrowIconProps {
  open: boolean;
}

export const ArrowIcon = styled.svg<ArrowIconProps>`
  ${({open}) =>
    !open &&
    css`
      transform: rotate(180deg);
    `}
`;

export const OpenButton = styled.button`
  width: 100%;
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(12)};
  font-size: ${fluidSize(12, 14)};
  color: ${theme.colors.grey[500]};
  background-color: ${theme.colors.grey[100]};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(201, 202, 202, 0.32);
`;

interface OptionsWrapperProps {
  open: boolean;
}

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  position: absolute;
  bottom: 0px;
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
  transform: translateY(-50%);
`;

export const Option = styled.button`
  background: ${theme.colors.white};
  backdrop-filter: blur(12px);
  padding: ${pxToRem(12)};
  color: ${theme.colors.grey[400]};
  font-size: ${fluidSize(12, 14)};
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid ${theme.colors.grey[200]};
  white-space: nowrap;
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: 0;
  }
`;

interface OptionIconWrapperProps {
  selected: boolean;
}

export const OptionIconWrapper = styled.div<OptionIconWrapperProps>`
  width: 14px;
  height: 14px;
  background-color: ${props =>
    props.selected ? theme.colors.lightGreen[400] : theme.colors.grey[100]};
  margin-left: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
