import React, {useEffect, useState} from "react";
import {
  HorizontalSelectWrapper,
  LastMonth,
  NoDataText,
  Row,
  Value,
  ValueDescription,
  Wrapper,
  WrapperContent,
} from "./emissionByYear.style";
import {ValueIndicator} from "../ui/valueIndicator";
import HorizontalSelect from "../ui/horizontalSelect/horizontalSelect";
import {
  CardHeader,
  CardHeaderContent,
  CardSubTitle,
  CardTitle,
} from "../ui/card";
import calculateDiffrenceInPercent from "@/helpers/calculateDiffrenceInPercent";

export interface EmissionByYearInterface {
  year: string;
  emission: number;
}

interface Props {
  values: EmissionByYearInterface[];
  className?: string;
}

const EmissionByYear = ({values, className}: Props): JSX.Element => {
  const [emission, setEmission] = useState(0);
  const [emissionDiffrence, setEmissionDiffrence] = useState(0);
  const [emissionDiffrencePercent, setEmissionDiffrencePercent] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(values.length - 1);

  useEffect(() => {
    changeYear(selectedIndex);
  }, [values]);

  const changeYear = (index: number): void => {
    setSelectedIndex(index);
    setEmission(values[index].emission);
    if (index <= 0) {
      setEmissionDiffrence(0);
      return;
    }
    setEmissionDiffrence(values[index].emission - values[index - 1].emission);
    setEmissionDiffrencePercent(
      calculateDiffrenceInPercent(
        values[index - 1].emission,
        values[index].emission,
      ),
    );
  };

  return (
    <Wrapper className={className}>
      <CardHeader>
        <CardHeaderContent>
          <CardTitle>
            CO<sub>2</sub> Emission
          </CardTitle>
          <CardSubTitle>By year</CardSubTitle>
          <HorizontalSelectWrapper>
            <HorizontalSelect
              values={values.map(value => value.year)}
              onChange={(index, value) => {
                changeYear(index);
              }}
              defaultIndex={values.length - 1}
            />
          </HorizontalSelectWrapper>
        </CardHeaderContent>
      </CardHeader>
      <WrapperContent>
        <Row>
          <Value>{emission.toLocaleString()}</Value>
          <ValueDescription>
            tCO<sub>2</sub>
          </ValueDescription>
        </Row>

        <LastMonth>Last year:</LastMonth>
        {emissionDiffrence !== 0 && (
          <ValueIndicator positive={emissionDiffrencePercent < 0}>
            {emissionDiffrence.toLocaleString()} tCO<sub>2</sub>
            <sup>({emissionDiffrencePercent.toPrecision(3)}%)</sup>
          </ValueIndicator>
        )}
        {emissionDiffrence === 0 && <NoDataText>No data</NoDataText>}
      </WrapperContent>
    </Wrapper>
  );
};

export default EmissionByYear;
