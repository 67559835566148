import styled from "styled-components";
import Card, {CardContent} from "../ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

export const Row = styled(CardContent)`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${pxToRem(33)};
`;

export const Value = styled.span`
  font-size: ${fluidSize(32, 40)};
  font-weight: 700;
  color: ${theme.colors.black};
`;

export const ActivityChartWrapper = styled.div``;

export const ValueDescription = styled.span`
  font-size: ${fluidSize(16, 20)};
  font-weight: 700;
  color: ${theme.colors.black};
  margin-left: ${pxToRem(16)};
`;

export const EmissionChartWrapper = styled(CardContent)`
  padding-top: ${pxToRem(32)};
  padding-left: 0;
  height: ${pxToRem(173)};
  background-color: rgba(239, 239, 239, 0.4);
`;

export const LastMonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LastMonth = styled.span`
  font-size: ${fluidSize(8, 10)};
  color: ${theme.colors.grey[400]};
  margin-bottom: ${pxToRem(8)};
`;
