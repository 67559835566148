import React from "react";
import {
  BatteryWrapper,
  Column,
  Content,
  Text,
  Wrapper,
} from "./batteryCard.style";
import Battery from "../battery/battery";

interface Props {
  battery: number;
  amper: number;
  volt: number;
  temperature: number;
}

const BatteryCard = ({
  battery,
  amper,
  volt,
  temperature,
}: Props): JSX.Element => {
  return (
    <Wrapper>
      <Content>
        <BatteryWrapper>
          <Battery value={battery} />
        </BatteryWrapper>

        <Column>
          <Text primary={true}>{battery.toFixed(1)}%</Text>
          <Text primary={false}>{amper.toFixed(1)} A</Text>
          <Text primary={false}>{volt.toFixed(1)} V</Text>
          <Text primary={false}>{temperature.toFixed(1)} °C</Text>
        </Column>
      </Content>
    </Wrapper>
  );
};

export default BatteryCard;
