import React, {useEffect, useState} from "react";
import {
  ActivityChartWrapper,
  Column,
  EmissionChartWrapper,
  LastMonth,
  LastMonthWrapper,
  Row,
  Value,
  ValueDescription,
  Wrapper,
} from "./scopeBox.style";
import {CardHeader, CardTitle} from "../ui/card";
import type IEmission from "@/interfaces/IEmission";
import type {ActivityChartData} from "../charts/activityChart/activityChart";
import ActivityChart from "../charts/activityChart/activityChart";
import EmissionChart from "../charts/emissionChart/emissionChart";
import {ValueIndicator} from "../ui/valueIndicator";
import calculateDiffrenceInPercent from "@/helpers/calculateDiffrenceInPercent";

interface Props {
  title: string;
  thisMonth: number;
  lastMonth: number;
  emissions: IEmission[];
  activity: ActivityChartData[];
  className?: string;
}

const getTextFromValue = (value: string): string => {
  switch (value) {
    case "consumption":
      return "Consumption";
    case "tco2":
      return "tCO<sup>2</sup>";
    case "energy":
      return "Energy";
  }
  return "";
};

const ScopeBox = ({
  title,
  thisMonth,
  lastMonth,
  emissions,
  activity,
  className,
}: Props): JSX.Element => {
  const [emissionDiffrence, setEmissionDiffrence] = useState(0);
  const [emissionDiffrencePercent, setEmissionDiffrencePercent] = useState(0);

  useEffect(() => {
    setEmissionDiffrence(thisMonth - lastMonth);
    setEmissionDiffrencePercent(
      calculateDiffrenceInPercent(thisMonth, lastMonth),
    );
  }, [lastMonth, thisMonth]);
  return (
    <Wrapper className={className}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <Row>
        <Column>
          <Value>
            {thisMonth}
            <ValueDescription>
              tCO<sub>2</sub>
            </ValueDescription>
          </Value>
          <LastMonthWrapper>
            <LastMonth>Last month:</LastMonth>
            <ValueIndicator positive={emissionDiffrencePercent > 0}>
              {emissionDiffrence.toPrecision(3)} tCO
              <sub>2</sub>
              <sup>({emissionDiffrencePercent.toPrecision(3)}%)</sup>
            </ValueIndicator>
          </LastMonthWrapper>
        </Column>
        <ActivityChartWrapper>
          <ActivityChart
            data={activity.map((a, key) => {
              return {
                key,
                text: getTextFromValue(a.name),
                ...a,
              };
            })}
          />
        </ActivityChartWrapper>
      </Row>
      <EmissionChartWrapper>
        <EmissionChart
          legend={true}
          data={emissions.map((e, key) => {
            const month = key + 1;
            return {
              key,

              name: month.toString(),
              ...e,
            };
          })}
        />
      </EmissionChartWrapper>
    </Wrapper>
  );
};

export default ScopeBox;
