import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const LegendText = styled.span`
  color: ${theme.colors.grey[500]};
  font-size: ${fluidSize(10, 12)};
  padding-left: ${pxToRem(8)};
`;

export const LegendTextCapitalize = styled(LegendText)`
  text-transform: capitalize;
`;
