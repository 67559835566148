import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  gap: ${pxToRem(16)};
  cursor: pointer;
`;

interface InputProps {
  color: string;
}

export const Input = styled.input<InputProps>`
  appearance: none;
  background-color: #fff;
  margin: 0;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border: 1px solid ${props => props.color};
  padding: ${pxToRem(4)};
  border-radius: 4px;
  transition: all 0.3s;
  &:checked {
    background-color: ${props => props.color};
    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M5.86339 10.5834L3.08339 7.80336L2.13672 8.74336L5.86339 12.47L13.8634 4.47003L12.9234 3.53003L5.86339 10.5834Z" fill="%23D2E6AE"/></svg>');
    }
  }
  cursor: pointer;
`;

export const Label = styled.label`
  color: ${theme.colors.grey[500]};
  font-size: ${fluidSize(16, 18)};
  font-weight: 700;
  cursor: pointer;
`;
