import {pxToRem} from "@/styles/helpers";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  padding-left: ${pxToRem(20)};
  padding-right: ${pxToRem(20)};
  min-height: 100vh;
`;

export default Container;
