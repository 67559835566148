import React from "react";

const CustomizedTick = (props: any): JSX.Element => {
  const {x, y, payload, color, textAnchor, percent} = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} fontSize={12} textAnchor={textAnchor} fill={color}>
        {payload.value} {percent !== null && percent === true && "%"}
      </text>
    </g>
  );
};

export default CustomizedTick;
