import React from "react";
import Card, {CardHeader} from "../ui/card";
import {
  BatteryComponent,
  BatteryTitle,
  BatteryWrapper,
  Content,
  DataWrapper,
  InfoTitle,
  InfoValue,
  InfoWrapper,
} from "./sources.style";

interface Props {
  battery: number;
  amper: number;
  volt: number;
  temperature: number;
  power: number;
  l1: number;
  l2: number;
  l3: number;
}

const Sources = ({
  battery,
  amper,
  volt,
  temperature,
  power,
  l1,
  l2,
  l3,
}: Props): JSX.Element => {
  return (
    <Card>
      <CardHeader>Sources</CardHeader>
      <Content>
        <DataWrapper>
          <InfoWrapper>
            <InfoTitle>P:</InfoTitle>
            <InfoValue>{power} W</InfoValue>
          </InfoWrapper>
          <InfoWrapper></InfoWrapper>
          <InfoWrapper>
            <InfoTitle>
              L<sub>1</sub>:
            </InfoTitle>
            <InfoValue>{l1} V</InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoTitle>Current::</InfoTitle>
            <InfoValue>{amper} V</InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoTitle>
              L<sub>2</sub>:
            </InfoTitle>
            <InfoValue>{l2} V</InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoTitle>Temp:</InfoTitle>
            <InfoValue>{temperature} °C</InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoTitle>
              L<sub>3</sub>:
            </InfoTitle>
            <InfoValue>{l3} V</InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoTitle>Potencial:</InfoTitle>
            <InfoValue>{volt} V</InfoValue>
          </InfoWrapper>
        </DataWrapper>
        <BatteryWrapper>
          <InfoWrapper>
            <InfoTitle>Battery:</InfoTitle>
            <BatteryTitle>{battery} %</BatteryTitle>
          </InfoWrapper>
          <BatteryComponent value={battery} />
        </BatteryWrapper>
      </Content>
    </Card>
  );
};

export default Sources;
